import React, {useState} from "react";
import Layout from "../components/layout";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {FaTelegramPlane} from "react-icons/fa";
import SEO from "../components/seo";
import axios from "../components/axios";

const Contact = ({location}) => {

    const [emailAddress, setEmailAddress] = useState((location.state && location.state.email) ? location.state.email : "");
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const strIsEmpty = (str: string) => {
        return str.trim() == ""
    }

    const handleSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }
        setValidated(true);

        const url = '/v1/contact-form-landing-page/'
        const formData = new FormData()
        formData.append('email_address', emailAddress)
        formData.append('full_name', fullName)
        formData.append('company_name', companyName)
        formData.append('message', message)

        axios.post(url, formData).then((response) => {
            if (response.status === 200) {
                setErrorMessage("")
                setSuccessMessage("Email sent. You'll hear from us soon!")
            }
            setIsLoading(false)
        }).catch(_error => {
            setSuccessMessage("")
            setErrorMessage("Sorry, we could not send your email.. Please send an email manually to contact@greengard.no")
            setIsLoading(false)
        })
    };

    return (
        <Layout>
            <SEO title={'Contact'}/>

            <section className="py-5 mt-5 bg-light-lighten">
                <Container>
                    <Row className={'justify-content-center'}>
                        <Col lg={6}>
                            <div className="text-center text-light">
                                <h3 className="text-orange">Get In Touch</h3>
                                <p className="mt-2">We respond quickly!</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center mt-3 pb-5">
                        <Col lg={6}>
                            <Form noValidate validated={validated} className={'was-validated'} onSubmit={handleSubmit}>
                                <Row className="mt-4">
                                    {/* Email */}
                                    <Form.Group as={Col} lg={6} controlId="emailaddress" className={'mb-2'}>
                                        <Form.Label className={'text-light'}>Your email address</Form.Label>
                                        <Form.Control
                                            required
                                            type={'email'}
                                            placeholder="Enter your email"
                                            value={emailAddress}
                                            onChange={(e) => setEmailAddress(e.target.value)}
                                        />
                                    </Form.Group>

                                    {/* Full name */}
                                    <Form.Group as={Col} lg={6} controlId="fullname" className={'mb-2'}>
                                        <Form.Label className={'text-light'}>Your full name</Form.Label>
                                        <Form.Control
                                            required
                                            type={'text'}
                                            placeholder="Enter your full name"
                                            value={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mt-1">
                                    {/* Company name */}
                                    <Form.Group as={Col} lg={12} controlId="companyname" className={'mb-2'}>
                                        <Form.Label className={'text-light'}>Your company name</Form.Label>
                                        <Form.Control
                                            required
                                            type={'text'}
                                            placeholder="Enter your company name"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mt-1">
                                    {/* Message */}
                                    <Form.Group as={Col} lg={12} controlId="message" className={'mb-2'}>
                                        <Form.Label className={'text-light'}>Message (Optional)</Form.Label>
                                        <Form.Control
                                            as={'textarea'}
                                            rows={4}
                                            placeholder="Type your message here.."
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mt-2">
                                    <Col xs={12} className="text-start">
                                        <Button type={'submit'} className="btn-orange"
                                                disabled={isLoading || !strIsEmpty(successMessage) || !strIsEmpty(errorMessage)}>
                                            Send
                                            {' '}
                                            {isLoading ?
                                                <Spinner animation="border" role="status" size={"sm"}>
                                                    <span className="visually-hidden">Sending email..</span>
                                                </Spinner>
                                                :
                                                <FaTelegramPlane/>}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            {!strIsEmpty(successMessage) &&
                                <Row className="mt-3">
                                    <Col xs={12} className="">
                                        <Alert className={"alert-orange"}
                                               onClose={() => setSuccessMessage("")} dismissible>
                                            {successMessage}
                                        </Alert>
                                    </Col>
                                </Row>
                            }

                            {!strIsEmpty(errorMessage) &&
                                <Row className="mt-3">
                                    <Col xs={12} className="">
                                        <Alert className={"alert-red"}
                                               onClose={() => setErrorMessage("")} dismissible>
                                            {errorMessage}
                                        </Alert>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>


                    <Row className="justify-content-center mt-5">
                        <Col lg={6} className={'text-center'}>
                            <p className="text-muted my-4">
                                <span className="fw-bold">Customer Support</span>
                                <br/>
                                <a href={"mailto:contact@greengard.no"}><span className="d-block mt-1">contact@greengard.no</span></a>
                                <a href={"tel:+4791695807"}><span className="d-block mt-1">+47 916 95 807</span></a>
                            </p>
                        </Col>
                    </Row>

                </Container>
            </section>

        </Layout>
    )
}

export default Contact